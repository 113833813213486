/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input, Dropdown, TextArea, Form } from 'semantic-ui-react';
import ReactJson from 'react-json-view';
import { mdiClose } from '@mdi/js';
import IconButton from '../Block/components/FilterData/IconButton';
import GBButton from '../../components/GBButton/GBButton';
import { aiProcessAPI } from '../../api/ai';
import constructPath from './constructJSONPath';
import Spinner from '../../components/Spinner/Spinner';

const ActionAIProcess = ({ localData, updateData, editMode, sourceNodes }) => {
  const [isLoading, setIsLoading]= useState(false);
  const [AIProcessName,setAIProcessName] =useState(localData.AIProcessName ?? '');
  const [allFields, setAllFields] = useState([]);
  const [model, setModel] = useState(localData.model ?? 'gemini-2.0-flash')
  const [promptMessage, setPromptMessage] = useState(localData.promptMessage ?? '');
  const [APIResponse, setAPIResponse] = useState(localData.apiData ?? null);
  const [tableData, setTableData] = useState(
    localData.tableData ?? {
      name: `api_${localData.id}`,
      id: `api_${localData.id}`,
      tableinfo: { columns: [] },
    },
  );
  const [tokenArray, setTokenArray] = useState(localData.tokenArray ?? []);

  useEffect(() => {
    getAllFields();
  }, []);


  const updateAIProcessName = (val) =>{
    setAIProcessName(val);
    tableData.name=val;
    localData.tableData=tableData;
    localData.AIProcessName=val;
    updateData(localData);
  }

  const modelOptions=[
    {key:'gemini-2.0-flash', value:'gemini-2.0-flash', text:'Gemini 2.0 Flash'},
    {key:'gemini-2.0-flash-lite', value:'gemini-2.0-flash-lite', text:'Gemini 2.0 Flash-Lite'},
    {key:'gemini-2.0-pro-exp-02-05',value:'gemini-2.0-pro-exp-02-05', text:'Gemini 2.0 Pro (experimental)'},
    {key:'gemini-2.0-flash-thinking-exp-01-21',value:'gemini-2.0-flash-thinking-exp-01-21', text:'Gemini 2.0 Flash Thinking (Experimental)'},
  ]

  const addJsonElement = (val) => {
    let path = constructPath(val,APIResponse);
  
    const copyTableData = structuredClone(tableData);
    copyTableData.tableinfo.columns = tableData.tableinfo.columns ?? [];
    const idx = copyTableData.tableinfo.columns.findIndex((col) => col.data === val.name);
    if (idx === -1) {
      const uitype = val.type === 'integer' ? 23 : 2;
      copyTableData.tableinfo.columns.push({
        data: val.name,
        header: val.name,
        uitype: uitype,
        path: path,
      });
      setTableData(copyTableData);
      localData.tableData = copyTableData;
      updateData(localData);
    }
  };

  const removeJsonElement = (header) => {
    const copyTableData = structuredClone(tableData);
    copyTableData.tableinfo.columns=copyTableData.tableinfo.columns.filter(col=>col.header !==header)
    setTableData(copyTableData)
    localData.tableData = copyTableData;
    updateData(localData);
  }

  const updateModel = (val) =>{
    setModel(val)
    localData.model=val;
    updateData(localData);
  }

  const extractUniqueTokens = (inputString) => {
    const regex = /\{\{([^}]+)\}\}/g;
    const tokens = [];
    let match;

    while ((match = regex.exec(inputString)) !== null) {
      const token = match[0];

      // Check if the token is already in the array
      const tokenExists = tokens.some((existingToken) => existingToken.token === token);

      if (!tokenExists) {
        tokens.push({
          token: token,
          value: '',
        });
      }
    }

    return tokens;
  };

  const updateTokenValue = (token, value) => {
    const copyArray = structuredClone(tokenArray);
    const idx = copyArray.findIndex((tkn) => tkn.token === token);
    if (idx !== -1) {
      copyArray[idx].value = value;
    }
    setTokenArray(copyArray);
  };

  const updatePromptMessage = (val) => {
    setPromptMessage(val);
    const tokens = extractUniqueTokens(val);
    setTokenArray(tokens);

    localData.promptMessage = val;
    localData.tokenArray = tokens;

    if(localData.model===undefined) {
      localData.model=model;
    }

    updateData(localData);
    //pare out tokena nd put into array, so user can enter test values.
  };

  const callAIProcess = async () => {
    setIsLoading(true);
    let finalPromptMessage = promptMessage;

    tokenArray.map((tkn) => {
      finalPromptMessage = finalPromptMessage.replaceAll(tkn.token, tkn.value);
    });

    const gbResponse = await aiProcessAPI(finalPromptMessage,'aiprocess',null,model);
    const cleanedResponse = gbResponse.response
      .trim()
      .replace('```json', '')
      .replace('```', ''); // Replace NO-BREAK SPACE with regular space

    let jsonObject = null;

    try {
      jsonObject = JSON.parse(cleanedResponse);
    } catch (error) {
      console.log(cleanedResponse)
      console.log(error)
      jsonObject = { data: cleanedResponse };
    }

    // const jsonObject = JSON.parse(cleanedResponse);
    setAPIResponse(jsonObject);

    localData.apiData = jsonObject;
    updateData(localData);
    setIsLoading(false)
    
  };

  const getAllFields = () => {
    const allOptions = [{ key: '0', value: '', text: 'Choose field' }];

    sourceNodes.map((node) => {
      node.data.insertMultiple == null &&
        node.data?.tableData?.tableinfo?.columns.map((col) => {
          const idx = allOptions.findIndex(
            (itm) => itm.key === `${node.data.tableData.id}||${col.data}`,
          );
          if (idx === -1) {
            allOptions.push({
              key: `${node.data.tableData.id}||${col.data}`,
              value: `${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
              text: `${node.data.tableData.name}||${col.header}`,
            });
          }
        });
    });

    setAllFields(allOptions);
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        AI Process Name: <Input value={AIProcessName} fluid onChange={(e,data)=>updateAIProcessName(data.value)} />
      </div>
      <div style={{marginTop:'10px',marginBottom:'10px'}}>
        AI Model: <Dropdown selection fluid options={modelOptions} value={model} onChange={(e,data)=>updateModel(data.value)} />
      </div>

      <div style={{marginTop:'10px'}}>Construct AI prompt to process data</div>
      <div>
        <div style={{ marginTop: '15px', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>Field tokens</div>
            <Dropdown
              clearable
              search
              selectOnBlur={false}
              options={allFields}
              fluid
              selection
              onChange={(e, data) =>
                data.value != '' ? updatePromptMessage(promptMessage + `{{${data.value}}}`) : null
              }
            />
          </div>

          <Form>
            <TextArea
              style={{
                marginTop: '20px',
                fontSize: '12px',
                height: '200px',
                border: '1px solid black',
                borderRadius: '10px',
                // width: '470px',
              }}
              onChange={(e, data) => updatePromptMessage(data.value)}
              value={promptMessage}
              placeholder="Enter AI Prompt here"
            />
          </Form>

          {tokenArray.length > 0 ? (
            <table style={{ marginTop: '10px' }}>
              <tr>
                <th>Token</th>
                <th>Test value</th>
              </tr>
              {tokenArray.map((tkn) => (
                <tr>
                  <td>{tkn.token}</td>
                  <td>
                    <Input
                      value={tkn.value}
                      onChange={(e, data) => updateTokenValue(tkn.token, data.value)}
                    />
                  </td>
                </tr>
              ))}
            </table>
          ) : null}

          {!isLoading ? (
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <GBButton text="Get response" padding="10px" color={'#0D99FF'} Action={callAIProcess} />
          </div>
          ): <Spinner color={'#0D99FF'} />}

          {APIResponse ? (
            <div style={{height:'300px',overflow:'auto',border:'1px solid black'}}>
            <ReactJson theme="ocean" onSelect={addJsonElement} src={APIResponse} />
            </div>

          ) : null}
          <div style={{ marginTop: '5px', marginBottom: '5px', fontWeight: 'bold' }}>
            Fields to capture
          </div>
          {tableData?.tableinfo.columns?.map((col) => (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ marginRight: '5px' }}>{col.header}</div>
              <div>
                <IconButton
                  icon={mdiClose}
                  iconSize="20px"
                  color={'#E06055'}
                  hoverColor={'#E0605580'}
                  Action={removeJsonElement}
                  ActionValue={col.header}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActionAIProcess;
