/* eslint-disable */
import moment from 'moment';

export const TO_NUMBER = (val) => {
    let numString = val.replace(/[$,]/g, ''); // Remove '$' and commas
    if (val.endsWith('%')) {
      numString = numString.slice(0, -1);
      const number = parseFloat(numString);
      if (!isNaN(number)) {
        return number / 100;
      } else {
        return null;
      }
    }
  
    const match = numString.match(/(-?\d*\.?\d+)/);
    if (match) {
      return parseFloat(match[0]);
    } else {
      return null;
    }
  };

  
export const NOW = () =>{
    const now = moment();
    return now;
   }

export  const TO_DATE=(val) =>{
    if (!val) return null;

    const dateFormats = [
        'YYYY-MM-DD',  // ISO 8601 Date
        'MM/DD/YYYY',  // US Format
        'DD/MM/YYYY', // European format
        'YYYY/MM/DD',
        'DD-MMM-YYYY',
        'dddd, MMMM D, YYYY'
    ];

    const timeFormats = [
        'HH:mm',       // 24-hour, minutes
        'HH:mm:ss',    // 24-hour, minutes, seconds
        'hh:mm A',     // 12-hour, minutes, AM/PM
        'hh:mm:ss A'  // 12-hour, minutes, seconds, AM/PM
    ];

    for (const dateFormat of dateFormats) {
        for (const timeFormat of timeFormats) {
            const combinedFormat = `${dateFormat} ${timeFormat}`; // Try combined
            let d = moment(val, combinedFormat, true); // Strict parsing
            if (d.isValid()) {
                return d.toDate();
            }

            //If combined fails, and time is included, try without the time
            if(timeFormat != null && timeFormat != "") {
                d = moment(val, dateFormat, true);
                if (d.isValid()) {
                    return d.toDate();
                }
            }

        }
        //If no time is included, try date only
        let d = moment(val, dateFormat, true);
        if (d.isValid()) {
            return d.toDate();
        }
    }

    console.error('Date conversion error: Unknown format');
    return null;
}

// export const TO_DATE=(val) =>{
//     const d = moment(val)
//     return d;
//   }


 export const DATEADD = (date1, num, interval) => {
    const newData = new Date(date1);

    switch (interval.toLowerCase()) {
        case 'seconds':
            newData.setSeconds(newData.getSeconds() + num);
            break;
        case 'minutes':
            newData.setMinutes(newData.getMinutes() + num);
            break;
        case 'hours':
            newData.setHours(newData.getHours() + num);
            break;
        case 'days':
            newData.setDate(newData.getDate() + num);
            break;
        case 'weeks':
            newData.setDate(newData.getDate() + num * 7);
            break;
        case 'months':
            newData.setMonth(newData.getMonth() + num);
            break;
        case 'years':
            newData.setFullYear(newData.getFullYear() + num);
            break;
        default:
            throw new Error('Invalid interval specified');
    }

    return newData.toISOString(); 
};

export const CONCAT =(...strings) =>{
    return strings.join(''); 
  }



